/* eslint-disable no-else-return */
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'; // components
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  Button,
  Divider,
  TextField,
  Typography,
  Autocomplete,
  DialogContent,
} from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { decimalLimit } from 'src/server/api/http';
import { getBeneficiaryMembers } from 'src/server/api/member';
import { ResentOtpApi, verifyOtpApi } from 'src/server/api/auth';
import { createWallet2WalletTransferMember } from 'src/server/api/W2WTransfer';

import Label from 'src/components/label';
import W2WBoardingStepper from 'src/components/W2Wstepper';
import { useSettingsContext } from 'src/components/settings';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

W2WTransferCreateModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenSuccessModal: PropTypes.func,
};

export default function W2WTransferCreateModal({ open, setOpen, setOpenSuccessModal }) {
  // const theme = useTheme();
  const { themeMode } = useSettingsContext();
  const dispatch = useDispatch();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Choose Beneficiary ', 'Amount', 'Verification'];
  const [selectedMember, setSelectedMember] = useState('');
  const [searchMember, setSearchMember] = useState('');
  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const {
    membersList,
    memberProfileDetails,
    otpLoading,
    // memberWalletAddress: memberWalletAddressDetails,
    // memberWalletAddressList,
  } = useSelector((state) => ({
    loading: state.member.loading,
    membersList: state.member?.beneficiaryMembers?.members,
    memberProfileDetails: state.member.memberProfileDetails.member,
    settingsData: state.settings.settingsDetails,
    otpLoading: state.auth?.loading,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowSuccess = () => {
    setOpenSuccessModal(true);
  };

  const isVerified = useBoolean();

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 1) {
      const otpResetCrud = {
        state: {
          email: memberProfileDetails?.email,
        },
        type: 'resend_otp',

        dispatch,
      };
      dispatch(ResentOtpApi(otpResetCrud));
    }
  };
  // const router = useRouter();

  useEffect(() => {
    const data = {
      page: '',
      search: searchMember,
      dispatch,
      limit: '',
      sort: 'VERIFIED',
    };
    dispatch(getBeneficiaryMembers(data));
  }, [dispatch, searchMember]);

  const optionMembers = [
    ...(membersList || []).map((item) => ({
      label: item?.name,
      value: item?._id,
      wallet_balance: item?.buks_wallet_balance,
      member_id: item?.member_id,
      email: item?.email,
      phone: item?.phone,
      username: item?.user?.username,
    })),
  ];

  const handleTextFieldChangeMember = (event) => {
    setSearchMember(event.target.value);
    // setPage();
  };

  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedMember(newValue || '');
    // setPage();
  };
  // const OtpSchema = Yup.object().shape({
  //   otp: Yup.string().min(4, 'OTP must be at least 4 digits').required('OTP is required'),
  // });

  const schema = Yup.object().shape({
    // beneficiary_member: Yup.string(),
    amount: Yup.number(),
    particulars: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      beneficiary_member: '',
      amount: '',
      particulars: '',
    }),
    []
  );
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    setValue,
  } = methods;

  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    isVerified?.onFalse();
    setValue('OTP', '');
  };

  console.log(errors);

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    const credentialsUpdate = {
      state: {
        ...data,
        // memberId: memberProfileDetails?.member_id,
        beneficiary_member: selectedMember?.value,
      },
      dispatch,
      handleClose,
      handleShowSuccess,
    };
    ['OTP']?.forEach((key) => {
      delete credentialsUpdate?.state[key];
    });
    dispatch(createWallet2WalletTransferMember(credentialsUpdate));
  });

  const otpField = watch('OTP');

  const handleVerifyOtp = () => {
    if (!isVerified?.value === true) {
      const otpCrud = {
        state: {
          otp: String(otpField),
          userId: memberProfileDetails?.user?._id,
        },
        dispatch,
        isVerified,
        enqueueSnackbar,
      };
      dispatch(verifyOtpApi(otpCrud));
    }
  };

  const amount = watch('amount');

  const handleControlAmount = () => {
    if (activeStep === 0 && selectedMember?.value) {
      return false;
    }
    if (activeStep === 1 && amount && amount < memberProfileDetails?.buks_wallet_balance) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="activate-member-modal"
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton> */}
          {/* <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'center', color: '#22C55E' }}> */}
          {/* </DialogTitle> */}
          <Stack py={3}>
            <W2WBoardingStepper activeStep={activeStep} steps={steps} />
          </Stack>
          <Divider
            sx={{
              width: '100%',
              // border: '1px solid #fff',
            }}
          />
          <DialogContent sx={{ minWidth: isMobile ? '250px' : '444px', padding: '0px', p: 3 }}>
            {activeStep === 0 && (
              <Stack pt={0} minHeight="250px">
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: '100%' }}
                  options={optionMembers}
                  autoHighlight
                  // size="small"
                  // className="small-select"
                  defaultValue={selectedMember?.value && selectedMember}
                  // getOptionLabel={(option) =>
                  //   `${option.label || ''} | ${option.member_id || ''} | ${option.username || ''} | ${option.email || ''} | ${option.phone || ''}`
                  // }
                  getOptionLabel={(option) => option.label}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      [
                        option?.label,
                        option?.member_id,
                        option?.username,
                        option.email,
                        option.phone,
                      ].some((attr) => attr?.toLowerCase().includes(inputValue))
                    );
                  }}
                  onChange={handleAutocompleteChangeMember}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label} ({option.member_id})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Beneficiary"
                      placeholder="Select..."
                      onChange={handleTextFieldChangeMember}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-member',
                      }}
                    />
                  )}
                />
              </Stack>
            )}
            {activeStep === 1 && (
              <Box minHeight="250px">
                <Stack direction="row" alignItems="center" justifyContent="end">
                  <Label
                    sx={{
                      width: 'fit-content',
                      textAlign: 'center',
                      backgroundColor: '#00A76F29',
                      color: '#007867',
                      fontWeight: 500,
                    }}
                  >
                    Bucks Wallet Balance:{' '}
                    {memberProfileDetails?.buks_wallet_balance?.toFixed(decimalLimit)} {USDT}
                  </Label>
                </Stack>
                <Stack pt={2}>
                  <Stack>
                    <RHFTextField
                      onKeyDown={(e) => {
                        if (
                          e.key === '.' ||
                          e.key === ',' ||
                          e.key === '-' ||
                          e.key === 'ArrowDown'
                        ) {
                          e.preventDefault();
                        }
                      }}
                      type="number"
                      label="Enter Amount"
                      name="amount"
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                    />
                    {amount > memberProfileDetails?.buks_wallet_balance && (
                      <Typography variant="caption" color="red" pt={0.5} pl={0.5}>
                        Amount cannot exceed{' '}
                        <Typography component="span" variant="caption" color="red" fontWeight={600}>
                          {memberProfileDetails?.buks_wallet_balance?.toFixed(2)}
                        </Typography>
                      </Typography>
                    )}
                  </Stack>
                  <Stack mt={2}>
                    <RHFTextField
                      type="text"
                      label="Particulars"
                      name="particulars"
                      multiline
                      rows={3}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {activeStep === 2 && (
              <Box>
                <Typography variant="subtitle2" color="text.secondary" pb={0.5}>
                  Transfer Details
                </Typography>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '' : '#F4F6F8',
                    boxShadow: 'none',
                    p: 2,
                  }}
                >
                  <Stack direction="column">
                    <Typography variant="caption" color="text.disabled">
                      Member
                    </Typography>
                    <Typography pt={0.5} variant="subtitle2" color="text.primary" fontWeight={600}>
                      {selectedMember?.name}
                    </Typography>
                    <Label
                      sx={{
                        mt: 0.5,
                        backgroundColor: '#00B8D929',
                        color: '#006C9C',
                        borderRadius: '5px',
                        width: 'fit-content',
                        px: 1,
                        fontWeight: 500,
                      }}
                    >
                      {selectedMember?.member_id}
                    </Label>
                  </Stack>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '' : '#F4F6F8',
                    boxShadow: 'none',
                    p: 2,
                    mt: 2,
                  }}
                >
                  <Stack direction="column">
                    <Typography variant="caption" color="text.disabled" pb={0.3}>
                      Amount
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary">
                      {amount} {USDT}
                    </Typography>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '' : '#F2F8F6',
                    boxShadow: 'none',
                    p: '12px',
                    mt: 4,
                    borderRadius: '9px',
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    {' '}
                    <InfoIcon
                      sx={{
                        color: '#00A76F',
                      }}
                    />
                    <Typography pl={1} color="text.secondary" variant="body2">
                      An OTP has been sent to your email
                    </Typography>
                  </Stack>
                </Card>
                <Stack py={2}>
                  <Stack direction="row" alignItems="center" justifyContent="start">
                    <Stack width="100%">
                      <RHFTextField
                        name="OTP"
                        label="Enter OTP here"
                        type="text"
                        sx={{
                          width: '100%',
                        }}
                        // placeholder="Transaction Hash"
                        // defaultValue={isUpdate?.member?.payment_transaction_id}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{
                        //   readOnly: !!view,
                        // }}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        width: 'fit-content',
                        ml: 1,
                      }}
                    >
                      <LoadingButton
                        size="large"
                        disabled={!otpField}
                        onClick={handleVerifyOtp}
                        variant="contained"
                        loading={otpLoading}
                        sx={{
                          backgroundColor: isVerified?.value !== true ? '#007867' : '#22C55E14',
                          color: isVerified?.value !== true ? 'white' : '#118D57',
                          '&:hover': {
                            backgroundColor: isVerified?.value !== true ? '#007867' : '#22C55E28',
                            color: isVerified?.value !== true ? 'white' : '#0A6E43',
                          },
                        }}
                      >
                        {isVerified?.value === true ? 'Verified' : 'Verify'}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}
          </DialogContent>
          <Divider
            sx={{
              width: '100%',
              borderStyle: 'dashed',
            }}
          />
          {/* <DialogActions> */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            textAlign="end"
            mt={0}
            spacing={1}
            p={2}
            py={3}
          >
            <Stack>
              {activeStep > 0 && (
                <Button
                  onClick={handlePreviousStep}
                  sx={{
                    width: 'fit-content',
                  }}
                  startIcon={
                    <KeyboardArrowLeftIcon
                      sx={{
                        fontSize: 12,
                      }}
                    />
                  }
                >
                  Back
                </Button>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="end" spacing={1}>
              <Button variant="outlined" color="inherit" onClick={handleClose}>
                Cancel
              </Button>

              {activeStep === 2 ? (
                <LoadingButton
                  type="submit"
                  disabled={!isVerified?.value === true}
                  // onClick={handleSubmitMember}
                  variant="contained"
                  loading={isSubmitting}
                  sx={{
                    color: 'white',
                    width: 'max-content',
                    marginLeft: 'auto',
                    backgroundColor: '#007867',
                    '&:hover': {
                      backgroundColor: '#007867',
                    },
                  }}
                >
                  Initiate Transfer
                </LoadingButton>
              ) : (
                <Button
                  disabled={handleControlAmount()}
                  onClick={handleNextStep}
                  variant="outlined"
                  sx={{
                    backgroundColor: '#007867',
                    color: 'white',
                    width: '60px',
                    '&:hover': {
                      backgroundColor: '#007867',
                    },
                  }}
                >
                  Next
                </Button>
              )}
            </Stack>
          </Stack>
          {/* </DialogActions> */}
        </FormProvider>
      </Dialog>
    </div>
  );
}
