import * as React from 'react';
import PropTypes from 'prop-types';

// @mui
import { Box, Card, Stack, Divider, Typography } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';

import { decimalLimit } from 'src/server/api/http';

import { RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';

export const SummaryData = ({ data }) => {
  const { themeMode } = useSettingsContext();

  const { selectedFrom, selectedTo, amount, fromActiveButton, toActiveButton } = data;

  return (
    <Box py={2} px={2}>
      <Stack>
        <Typography pb={0.5} variant="subtitle2" color="text.secondary">
          Summary
        </Typography>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: themeMode === 'dark' ? '' : '#F4F6F8',
            boxShadow: 'none',
          }}
        >
          <Stack p={2}>
            <Typography variant="caption" color="text.secondary">
              {fromActiveButton === 'MEMBER' ? 'Member' : 'Company'}
            </Typography>
            <Typography fontWeight={600} pt={0.5} pb={0.3} variant="subtitle2" color="text.primary">
              {/* {(!selectedFrom?.member_id && selectedFrom?.label) ||
                (!selectedTo?.member_id && selectedTo?.label)} */}
              {selectedFrom?.label} {selectedFrom?.member_id && `(${selectedFrom?.member_id})`}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                backgroundColor: '#00A76F29',
                px: 1,

                borderRadius: '4px',
                width: 'fit-content',
              }}
            >
              <Typography color="text.secondary" fontWeight={400} fontSize="12px">
                BALANCE
              </Typography>
              <Typography pl={0.5} color="#118D57" fontWeight={400} fontSize="12px">
                {/* {(!selectedFrom?.member_id &&
                  selectedFrom?.wallet_balance?.toFixed(decimalLimit)) ||
                  (!selectedTo?.member_id &&
                    selectedTo?.wallet_balance?.toFixed(decimalLimit))}{' '} */}
                {selectedFrom?.wallet_balance?.toFixed(decimalLimit)}
                {USDT}
              </Typography>
            </Box>
          </Stack>
          <Divider
            sx={{
              mb: 2,
              mt: 0.5,
              width: '100%',
              borderStyle: 'solid',
            }}
          />
          <Stack px={2} pb={2}>
            <Typography variant="caption" color="text.secondary">
              {toActiveButton === 'MEMBER' ? 'Member' : 'Company'}
            </Typography>
            <Typography pt={0.5} pb={0.3} variant="subtitle2" fontWeight={600} color="text.primary">
              {/* {(selectedFrom?.member_id && selectedFrom?.label) ||
                (selectedTo?.member_id && selectedTo?.label)}{' '}
              ({selectedFrom?.member_id || selectedTo?.member_id}) */}
              {selectedTo?.label} {selectedTo?.member_id && `(${selectedTo?.member_id})`}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                backgroundColor: '#00A76F29',
                px: 1,
                borderRadius: '4px',
                width: 'fit-content',
              }}
            >
              <Typography color="text.secondary" fontWeight={400} fontSize="12px">
                BALANCE
              </Typography>
              <Typography pl={0.5} color="#118D57" fontWeight={400} fontSize="12px">
                {/* {(selectedFrom?.member_id && selectedFrom?.wallet_balance?.toFixed(decimalLimit)) ||
                  (selectedTo?.member_id && selectedTo?.wallet_balance?.toFixed(decimalLimit))}{' '} */}
                {selectedTo?.wallet_balance?.toFixed(decimalLimit)}
                {USDT}
              </Typography>
            </Box>
          </Stack>
        </Card>
        <Stack py={2}>
          <RHFTextField
            name="amount"
            onKeyDown={(e) => {
              if (e.key === '.' || e.key === ',' || e.key === '-' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }}
            type="number"
            size="medium"
            label="Amount"
            onPaste={(e) => {
              e.preventDefault(); // Prevent paste
            }}
            onCopy={(e) => {
              e.preventDefault(); // Prevent copy
            }}
          />
          {amount && amount > selectedFrom?.wallet_balance ? (
            <Typography variant="caption" color="red" pt={0.5} pl={0.5}>
              Amount cannot exceed{' '}
              <Typography component="span" variant="caption" color="red" fontWeight={600}>
                {selectedFrom?.wallet_balance?.toFixed(decimalLimit)}
              </Typography>
            </Typography>
          ) : (
            ''
          )}
          <Stack pt={2}>
            <RHFTextField
              type="text"
              size="medium"
              label="Particulars"
              multiline
              rows={4}
              name="particulars"
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

SummaryData.propTypes = {
  data: PropTypes.any,
};
