// import { useState } from 'react';

import Stack from '@mui/material/Stack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Card, Grid, Link, Button, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import img1 from 'src/assets/other/web3.jpeg';
// import img2 from 'src/assets/other/Image-2.png';
// import img3 from 'src/assets/other/Image-3.png';
import fileIcon from 'src/assets/other/file.png';
// eslint-disable-next-line import/no-unresolved

import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function NewLearningPage() {
  const learningPageData = [
    {
      id: 1,
      img: img1,
      isPdf: true,
      title: 'The gateway to web3',
      description: 'A beginner can begin here. Learn now!!',
    },
    // {
    //   id: 2,
    //   img: img2,
    //   isPdf: false,
    //   title: 'Your Journey Begins Here',
    //   description: 'Follow the guide to unlock all the app’s features and get the most out of it.',
    // },
    // {
    //   id: 3,
    //   img: img3,
    //   isPdf: false,
    //   title: 'Master the Winbuks in Minutes',
    //   description:
    //     'ake a quick tour to understand the features and start using the app like a pro.',
    // },
  ];

  return (
    <>
      <CustomBreadcrumbs
        heading="Learnings"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Learnings',
          },
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack>
        <Grid container spacing={2}>
          {learningPageData?.map((value, key) => (
            <Grid item md={4} lg={4} xs={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                  boxShadow: 'none',
                  height: '100%',
                }}
              >
                <Link
                  href={value?.isPdf ? `${paths?.dashboard?.learning_singleView(value?.id)}` : '#'}
                  sx={{
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'none', // Only include this if you want to explicitly override hover behavior
                    },
                  }}
                >
                  <Stack direction="column" justifyContent="space-between" height="100%">
                    <Stack>
                      <CardMedia
                        component="img"
                        src={value?.img}
                        sx={{
                          height: 220,
                          borderRadius: '15px',
                        }}
                      />
                      <Stack px={1} pb={2}>
                        <Typography
                          py={2}
                          variant="subtitle2"
                          fontWeight={600}
                          color="text.primary"
                        >
                          {value?.title}
                        </Typography>
                        <TextMaxLine
                          line={3}
                          // py={2}
                          variant="caption"
                          fontWeight={400}
                          color="text.secondary"
                        >
                          {value?.description}
                        </TextMaxLine>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="start"
                      spacing={1}
                      pb={1.5}
                      px={1}
                    >
                      <Label
                        sx={{
                          backgroundColor: '#C8FAD6',
                          color: '#007867',
                          borderRadius: '12px',
                          cursor: 'pointer',
                          px: 1,
                        }}
                      >
                        <AttachFileIcon
                          sx={{
                            fontSize: 14,
                          }}
                        />{' '}
                        <Typography variant="caption">Module {key + 1}</Typography>
                      </Label>

                      <Button
                        disabled={!value?.isPdf}
                        startIcon={
                          <CardMedia
                            component="img"
                            src={fileIcon}
                            sx={{
                              objectFit: 'contain',
                              height: '16px',
                              width: '16px',
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: '#F0F0F0',
                          color: 'text.secondary',
                          fontSize: 12,
                          fontWeight: 400,
                          py: 0.2,
                          px: 0.2,
                          borderRadius: '12px',
                          ':hover': {
                            backgroundColor: '#F0F0F0',
                            color: 'text.secondary',
                          },
                        }}
                      >
                        PDF
                      </Button>
                      {/* <Label
                        sx={{
                          backgroundColor: '#F0F0F0',
                          color: 'text.secondary',
                          borderRadius: '12px',
                          cursor: 'pointer',
                          ml: 1,
                          px: 1,
                        }}
                      >
                        <CardMedia
                          component="img"
                          src={fileIcon}
                          sx={{
                            objectFit: 'contain',
                            height: '16px',
                            width: '16px',
                          }}
                        />{' '}
                        <Typography variant="caption" pl={1}>
                          PDF
                        </Typography>
                      </Label> */}
                    </Stack>
                  </Stack>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
}
